import React, { useState, useEffect, useRef } from 'react';

import { useInView } from 'react-intersection-observer';
import { useSpring, config } from 'react-spring';
import { useScrollData } from './scroll-data';
import { useWindowSize } from './window-size';

import '../styles/components/marquee.scss';

const Marquee = ( { amount = 10, children } ) => {

    const textRef = useRef();
    const textWidth = useRef( 0 );
    const marqueeTextRef = useRef();
    const frameRef = useRef();
    const mounted = useRef( );

    const [ offset, setOffset ] = useState( 0 );
    const directionRef  = useRef( 1 );

    const windowSize = useWindowSize();
    const scroll = useScrollData();

    const { speed } = useSpring( {
        speed: ( directionRef.current === 1 ) ? 1 + ( scroll.speed.y / 100 ) : -1 - ( scroll.speed.y / 100 ),
        config: config.molasses
    } );

    const { ref, inView } = useInView( {
        threshold: 0,
    } );

    useEffect( () => {

        mounted.current = true;
        textWidth.current = textRef.current.getBoundingClientRect().width;

        return () => {
            cancelAnimationFrame( frameRef.current );
            mounted.current = false;
        };

    }, [] );

    useEffect( () => {

        if ( inView ) {
            frameRef.current = requestAnimationFrame( animate );
        } else {
            cancelAnimationFrame( frameRef.current );
        }

    }, [ inView ] );

    useEffect( () => {

        if ( scroll.direction.y === 'up' ) {
            directionRef.current = -1;
        } else if ( scroll.direction.y === 'down' ) {
            directionRef.current = 1;
        }

    }, [ scroll.direction.y ] );

    useEffect( () => {
        textWidth.current = textRef.current.getBoundingClientRect().width;
    }, [ windowSize.width ] );

    useEffect( () => {
        marqueeTextRef.current.setAttribute( 'style', `transform:translate3d(${offset - textWidth.current}px, 0, 0)` );
    }, [ offset ] );

    const animate = () => {

        if ( mounted.current === true ) {

            setOffset( ( prevOffset ) => {
                if ( directionRef.current === 1 && prevOffset > textWidth.current ) {
                    return 0;
                } else if ( directionRef.current === -1 && prevOffset < -textWidth.current ) {
                    return 0;
                } else {
                    return prevOffset + speed.value;
                }
            } );

            frameRef.current = requestAnimationFrame( animate );

        }

    };

    const renderCopies = () => {
        return Array( amount ).fill().map( ( key, index ) => {
            return <span key={index} className="text-copy" aria-hidden="true">
                {children}
            </span>;
        } );
    };

    return <section className="marquee" ref={ref}>
        <div className="marquee-text" ref={marqueeTextRef}>
            <span className="text" ref={textRef}>
                {children}
            </span>
            {renderCopies()}
        </div>
    </section>;

};

export default Marquee;
